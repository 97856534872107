import { graphql } from "gatsby";
import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./galerie.module.scss";

const GalleryPage = ({ data }) => {
  return (
    <div>
      <a href="/" className={styles.back}>
        &larr; Accueil
      </a>
      <h1 className={styles.title}>Galerie</h1>
      <ul className={styles.list}>
        {data.allMarkdownRemark.edges.map((painting) => (
          <li key={painting.node.id}>
            <GatsbyImage
              alt={painting.node.frontmatter.title}
              image={
                painting &&
                painting.node &&
                painting.node.frontmatter &&
                painting.node.frontmatter.image &&
                painting.node.frontmatter.image.childImageSharp &&
                painting.node.frontmatter.image.childImageSharp.gatsbyImageData
              }
            />
            <p>{painting.node.frontmatter.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GalleryPage;

export const pageQuery = graphql`
  query GalleryPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___image___birthTime }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            price
            medium
            dimensions
            date(fromNow: true)
            image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 350)
              }
            }
          }
        }
      }
    }
  }
`;
